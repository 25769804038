// Buttons

.btn {
    min-width: 135px;
    font-weight: 500;
    line-height: 1.3;
    padding: 13px 24.5px;
}

.btn-primary {
    @include button-variant(#BFE401, #BFE401, #B1D300, #B1D300, #B1D300, #B1D300);
}

.btn-secondary {
    @include button-variant($white, $gray-600, $primary, $primary, $primary, $primary);
    color: $gray-900;
}

.btn-payment-group > .btn-payment {
    font-weight: normal;
    padding: 13px 18px;
    min-width: 23%;
    margin-right: 2.5px;
    vertical-align: top;
    border: solid $border-width transparent;

    &:last-of-type {
        margin-right: 0;
    }
}
