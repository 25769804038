// Form

select {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIGZpbGw9J25vbmUnIHZpZXdCb3g9JzAgMCAyMCAyMCc+PHBhdGggc3Ryb2tlPScjNmI3MjgwJyBzdHJva2UtbGluZWNhcD0ncm91bmQnIHN0cm9rZS1saW5lam9pbj0ncm91bmQnIHN0cm9rZS13aWR0aD0nMS41JyBkPSdNNiA4bDQgNCA0LTQnLz48L3N2Zz4=);
    background-position: right .5rem center;
    background-repeat: no-repeat;
    background-size: 1.5em 1.5em;
    color-adjust: exact;
    appearance: none;
}

.form-control {
    padding: 8px 16px;
    height: 48px;
}

.input-group {
    &:focus-within .input-group-text {
        border-color: $input-focus-border-color;
    }

    .input-group-text {
        background-color: $input-bg;
    }

    .input-group-prepend > .input-group-text {
        border-right: none;
        padding-right: 0;
    }

    .input-group-append > .input-group-text {
        border-left: none;
        padding-left: 0;
    }
}

label:not(.form-check-label) {
    font-weight: 500;
}
