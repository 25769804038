// Card

.card {
    > .card-title {
        font-family: 'museo-sans', sans-serif;
        font-weight: 900;
        font-size: 1.375rem;
    }

    .card-subheading {
        font-size: 1.15rem;
        font-weight: 500;
    }
}
