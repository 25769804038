// Media-Queries

@include media-breakpoint-up(sm) {
    .w-sm-auto {
        width: auto !important;
    }

    .border-sm-left {
        border-left: $border-width solid $border-color !important;
    }

    .border-sm-right {
        border-right: $border-width solid $border-color !important;
    }
}

@include media-breakpoint-down(xs) {
    .btn-payment.invisible {
        display: none;
    }

    .rounded-xs-0 {
        border-radius: 0 !important;
    }

    .top-price-box > strong {
        font-size: 1.125rem;
    }

    .price-box {
        > label {
            font-size: 1rem;
        }
        > p {
            font-size: 2.125rem;
        }
    }

    .dot, .dot-img {
        width: 20px;
        height: 20px;
    }

    .steps-container {
        max-width: 160px;

        .connector {
            top: 34px;
        }

        .step .number {
            width: 18px;
            height: 18px;
        }
    }
}
